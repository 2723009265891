import React, { Component } from 'react';
import {
  Container,
  TextField,
  Avatar,
  Button,
  Typography,
  Alert
} from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions';
import Layout from '../../templates/layout';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { styled } from '@mui/material/styles';

const PREFIX = "EmailResend";
const classes = {
  paper: `${PREFIX}-paper`,
  avatar: `${PREFIX}-avatar`,
  submit: `${PREFIX}-submit`,
}
const RootContainer = styled(Container)(({theme}) => ({
  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  [`& .${classes.avatar}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  [`& .${classes.submit}`]: {
    margin: theme.spacing(3, 0, 2),
  },
}));

class EmailResend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      loading: false,
      error: '',
      success: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { resendVerificationEmail } = this.props;

    if (resendVerificationEmail !== prevProps.resendVerificationEmail) {
      const { status, reason, data } = resendVerificationEmail;

      if (data) this.setState({ success: true });
      if (status === 'ERROR') this.setState({ error: reason });
    }
  }

  handleResend = e => {
    e.preventDefault();
    const { email } = this.state;
    this.props.actions.resendVerificationEmail({ email });
  };

  handleChange = ({ target }) => {
    const email = target.value;
    const regExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = regExp.test(email);
    const error = email && !isValid ? 'Nieprawidłowy format email' : '';
    this.setState({ email, error });
  };

  render() {
    const { success, email, error } = this.state;
    return (
      <Layout >
        <RootContainer component='main' maxWidth='xs'>
          <form onSubmit={this.handleResend}>
            <div className={classes.paper}>
              {success ? (
                <Alert severity='success'>
                  Email weryfikacyjny został wysłany ponownie
                </Alert>
              ) : (
                <>
                  <Avatar className={classes.avatar}>
                    <MailOutlineIcon />
                  </Avatar>
                  <Typography component='h1' variant='h5' paragraph>
                    Wyślij ponownie email weryfikacyjny
                  </Typography>
                  <Typography variant='body1' paragraph>
                    Podaj adres email wykorzystany podczas rejestracji
                  </Typography>

                  <TextField
                    variant='outlined'
                    label='Email'
                    name='email'
                    type='email'
                    placeholder='Email'
                    value={email}
                    onChange={this.handleChange}
                    required
                    fullWidth
                    error={!!error}
                    helperText={error}
                  />

                  <Button
                    variant='contained'
                    color='primary'
                    type='submit'
                    disabled={!!error}
                    className={classes.submit}
                    fullWidth
                  >
                    Wyślij
                  </Button>
                </>
              )}
            </div>
          </form>
        </RootContainer>
      </Layout>
    );
  }
}

export const Head = () => <title>Weryfikacja adresu email | Zapłatomat</title>;

const mapStateToProps = ({ resendVerificationEmail }) => ({
  resendVerificationEmail,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailResend);
